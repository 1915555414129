// i18next-extract-mark-ns-start products-wave-cs3

import { WAVE_APPLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWavePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Reducing Emissions with a Real-time Engine Model</h2>
						<h3>
							Realis Simulation’s WAVE-RT Solution Successfully
							Decreased Vehicle Smoke Emissions by 5.5%
						</h3>
						<p>
							Diesel engines have long had a reputation of being
							“dirty.” On and off­ -road vehicle and engine
							manufacturers face the challenge of stringent fuel
							economy and emission standards while delivering on
							performance targets.Reducing Emissions with a Real-time
							Engine Model Realis Simulation formerly Ricardo
							Software’s WAVE-RT Solution Successfully Decreased
							Vehicle Smoke Emissions by 5.5%
						</p>
						<p>
							Diesel engines have long had a reputation of being
							“dirty.” On and off­ -road vehicle and engine
							manufacturers face the challenge of stringent fuel
							economy and emission standards while delivering on
							performance targets.
						</p>
						<p>
							The proposed solution is to implement a very accurate
							real-time engine model, running on the engine controller
							to better manage fuelling parameters during transient
							events. In this study, engineers applied Realis
							Simulation’s A significant opportunity exists to reduce
							emissions during transient engine operation (real-world
							driving), however this requires more advanced control
							strategies for fuelling as fuel delivery plays a major
							role in diesel engine emissions. The proposed solution
							is to implement a very accurate real-time engine model,
							running on the engine controller to better manage
							fuelling parameters during transient events. In this
							study, engineers applied Realis Simulation’s WAVE-RT
							technology on a running engine. WAVE-RT is unique in the
							industry as it is capable of accurately predicting
							engine behaviour in real-time (crank-angle resolved) and
							space (air paths and individual cylinders) - a
							significant advancement over traditional approaches.
						</p>
						<p>
							Using a high fidelity WAVE-RT model running onboard the
							engine, engineers designed advanced fuelling strategies.
							The specific approach was to poll the WAVE-RT model for
							trapped air mass in each cylinder (a quantity that can’t
							be measured on running engines, nor accurately predicted
							by traditional onboard engine models), then calculate a
							precise fuelling requirement to achieve a target
							air/fuel ratio. The approach was applied on an IVECO
							5.9L turbocharged diesel engine running on a transient
							dyno, with an ECU running the WAVE-RT model and updated
							fuelling control. As shown below, when tested over the
							World Harmonized Test Cycle, torque was maintained while
							achieving a 5.5% reduction in smoke.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWavePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-cs3", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
}
`;

